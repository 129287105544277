@font-face {
  font-family: 'Poppins Medium';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Medium.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Light';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Regular';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Poppins Bold';
  src: local('Poppins'), url(./fonts/Poppins/Poppins-Bold.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@media only screen and (min-width: 768px) {
.graph-height-width svg{
  width: 554px !important;
  height: 290px !important;
  overflow: scroll;
}
.btnrecipe{
  width: 468px !important;
}
}

.css-1ay6ak0-MuiDialogActions-root{
  display: block !important;
  padding-left: 27% !important;
}
.css-dhopo2{
  min-height: 214px !important;
}.cursor-pointer{
  cursor:pointer;
}
@media only screen and (max-width: 768px)  {
.css-1dr59fg{
  overflow-y: scroll;
  overflow-x: scroll;
}

.VictoryContainer {
user-select: auto !important;
pointer-events: auto !important;
touch-action: auto !important;
}
.graph-height-width svg{
  width: 343px !important;
  height: 290px !important;
  overflow: scroll;
}
 #box-mood-time svg{
  padding-bottom: 40px;
}
.btnrecipe{
  width: 325px !important;
}
}
